import React, { Component } from 'react'
import { graphql } from 'gatsby'
import he from 'he'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { Parallax } from 'react-scroll-parallax';

import Plyr from '../components/plyr'


//import { Link } from "../utils/link"
import { Link } from "gatsby"
import Fade from 'react-reveal/Fade'

import Seo from '../components/seo'

//import Logo from '../assets/images/logo.svg'
import Badge from '../assets/images/ico-badge.inline.svg'
import Grid from '../assets/images/ico-grid.inline.svg'

import Number from '../assets/images/ico-number.png'
//import GridAlt from '../assets/images/lines.svg'

class footballTemplate extends Component {

  render() {
    let page = this.props.data.wpPage

    return (
      <>
        <Seo title={he.decode(page.seo.title)} bodyClass={page.slug} description={page.seo.metaDesc} />

        <section id="list" className="football-list">
        { page.acf.footballImages && page.acf.footballImages.map((el, i) => (
            <div key={i} className='football-list__item'>

                {i === 0 &&
                  <div className='football-list__ico-number'>
                    <img className='number' src={Number} alt='Divock Origi' />
                  </div>
                }

                {i === 1 &&
                  <div className='football-list__ico-badge'><Badge /></div>
                }

                {i === 4 &&
                  <div className='football-list__ico-badge two'><Badge /></div>
                }

                {i === 6 &&
                  <div className='football-list__ico-badge three'><Badge /></div>
                }
{/* 
                {i === 3 &&
                  <div className='football-list__ico-grid one'><Grid /></div>
                } */}

                {i === 10 &&
                  <div className='football-list__ico-badge four'><Badge /></div>
                }

                {i === 11 &&
                  <div className='football-list__ico-grid'><Grid /></div>
                }

                <div className={'item__' + i }>

                  <Parallax translateY={[-15, 20]}>
                    <div className='first-img'>
                      <GatsbyImage className='football-list__image' loading='lazy' image={getImage(el.image?.localFile)} alt='Origi' />
                    </div>
                  </Parallax>

                </div>
            </div>
          )) }
          </section>

      </>
    )
  }
}

export const pageQuery = graphql`
  query ($id: String!) {
    wpPage(id: {eq: $id}) {
      title
      slug
      seo {
        title
        metaDesc
        opengraphTitle
        opengraphDescription
        opengraphImage {
          altText
          localFile {
            childImageSharp {
              original {
                src
                width
                height
              }
            }
          }
        }
      }
      acf {
        footballImages {
          fieldGroupName
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  quality: 75
                  width: 1800
                  formats: [AUTO, WEBP]
                  placeholder: DOMINANT_COLOR
                )
              }
            }
          }
        }
      }
    }
  }
`

export default footballTemplate
